<template>
  <div>
    <div class="pr-2" v-if="$router.currentRoute.name !== 'routes'">
      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>chevron_right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <div v-if="$router.currentRoute.name === 'routes'">
      <br />
      <h1 class="text-xs-center">Routes</h1>
      <div class="text-xs-right">
        <v-btn class="primary" :disabled="apiInProgress" @click="loadRoute('0')">
          <v-icon left>fal fa-plus</v-icon> Create Route
        </v-btn>
      </div>
      <v-layout row wrap>
        <v-flex md5 sm3 pa-2>
          <form @submit.prevent="() => searchRoutes(1)">
            <v-text-field
              solo
              v-model="searchKeywords"
              append-icon="fal fa-search"
              :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
              :disabled="apiInProgress"
              type="search"
              placeholder="Search route “label”"
              @click:append="() => searchRoutes(1)"
            />
          </form>
        </v-flex>
        <v-flex md4 sm4 pa-2>
          <v-layout>
            <v-flex xs6>
              <v-menu
                v-model="dateFromMenu"
                :close-on-content-click="false"
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    prepend-icon="fal fa-calendar"
                    :value="computedDateFromFormatted"
                    @click:clear="dateFrom = null"
                    clearable
                    label="Updated From"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  no-title
                  :max="computedDateFromMax"
                  v-model="dateFrom"
                  @change="dateFromMenu = false"
                />
              </v-menu>
            </v-flex>
            <v-flex xs6 pl-2>
              <v-menu
                v-model="dateToMenu"
                :close-on-content-click="false"
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    prepend-icon="fal fa-calendar"
                    :value="computedDateToFormatted"
                    @click:clear="dateTo = null"
                    clearable
                    label="To"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  no-title
                  :min="computedDateToMin"
                  v-model="dateTo"
                  @change="dateToMenu = false"
                />
              </v-menu>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 pa-2>
          <v-select
            dense
            :menu-props="{overflowX: true}"
            :items="agencies"
            label="Agency ID"
            :value="selectedAgencyId"
            @change="onFilterByAgency"
          />
        </v-flex>
        <v-flex md1 sm2 pa-2>
          <v-btn small fab @click.stop="searchRoutes(1)">
            <v-icon>fal fa-filter</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
        <v-data-table
          :headers="headers"
          :items="routes"
          :pagination.sync="pagination"
          :total-items="totalRoutes"
          :loading="apiInProgress"
          :rows-per-page-items="[10, 25, 50]"
          @update:pagination="onPagination"
          class="elevation-1"
          must-sort
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.label }}</td>
              <td :title="props.item.routeIdList">
                <v-text-field
                  class="text-xs-right text-field--flat"
                  :value="props.item.routeIdList"
                  readonly
                  hide-details
                />
              </td>
              <td>{{ props.item.agencyLabel }}</td>
              <td>{{ props.item.dateCreatedLabel }}</td>
              <td>{{ props.item.dateUpdatedLabel }}</td>
              <td class="justify-space-between layout px-0" style="min-width: 80px">
                <v-btn
                  flat
                  icon
                  title="Edit route"
                  :disabled="!hasUserType('admin')"
                  @click.stop="loadRoute(props.item.id)">
                  <v-icon small>
                    fas fa-pencil
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
    </div>
    <router-view
      :force-root-view-refresh="forceRootViewRefresh"
      :signed-in-user="signedInUser"
      :set-route="setRoute"
      :has-user-type="hasUserType">
    </router-view>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
