import { formatDate, formatTime } from '@smarttransit/common'
import moment from 'moment-timezone'
import { updateQueryParams } from '@smarttransit/common-client'

import { findStRoutes, findTotalStRoutes } from '@/services/st-routes-service'

import {
  isAuthorized,
  initBreadcrumbs
  // addAlert
} from '../../../utilities/helpers'

export default {
  props: {
    signedInUser: Object,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      routes: [],
      breadcrumbs: [],
      totalRoutes: 0,
      apiInProgress: false,
      pagination: { sortBy: 'dateUpdated', descending: true },
      selectedAgencyId: null,
      searchKeywords: '',
      headers: [],
      agencies: [],
      dateFromMenu: null,
      dateFrom: null,
      dateToMenu: null,
      dateTo: null
    }
  },
  watch: {
    $route () {
      this.breadcrumbs = initBreadcrumbs.call(this)

      if (this.$router.currentRoute.name === 'routes') {
        this.loadFromQueryParams()
        this.searchRoutes(1)
      }
    }
    // dateFrom (val) {
    //   this.updateQueryParams({ dateFrom: val })
    // },
    // dateTo (val) {
    //   this.updateQueryParams({ dateTo: val })
    // },
    // pagination (updatedPagination) {
    //   this.updateQueryParams({ ...updatedPagination })
    // }
  },
  computed: {
    computedDateFromFormatted () {
      return this.dateFrom ? formatDate(this.dateFrom) : ''
    },
    computedDateToFormatted () {
      return this.dateTo ? formatDate(this.dateTo) : ''
    },
    computedDateFromMax () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null

      if (toDateAsMoment && (!fromDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return toDateAsMoment.subtract(1, 'day').format('Y-MM-DD')
      }

      return ''
    },
    computedDateToMin () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null

      if (fromDateAsMoment && (!toDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return fromDateAsMoment.add(1, 'day').format('Y-MM-DD')
      }

      return ''
    }
  },
  mounted: function () {
    this.agencies = this.$store.getters.getAgencies

    if (!this.selectedAgencyId) {
      this.selectedAgencyId = this.agencies[0].value
    }

    this.$nextTick(() => {
      this.breadcrumbs = initBreadcrumbs.call(this)
    })

    if (this.$router.currentRoute.name === 'routes') {
      initTableHeaders.call(this)
      this.searchRoutes(1)
    }
  },
  methods: {
    loadFromQueryParams () {
      if (this.$route.query.descending) {
        this.pagination.descending = this.$route.query.descending
      }

      if (this.$route.query.page) {
        this.pagination.page = this.$route.query.page
      }

      if (this.$route.query.rowsPerPage) {
        this.pagination.rowsPerPage = this.$route.query.rowsPerPage
      }

      if (this.$route.query.sortBy) {
        this.pagination.sortBy = this.$route.query.sortBy
      }

      if (this.$route.query.searchKeywords) {
        this.searchKeywords = this.$route.query.searchKeywords
      }

      if (this.$route.query.dateFrom) {
        this.dateFrom = this.$route.query.dateFrom
      }

      if (this.$route.query.dateTo) {
        this.dateTo = this.$route.query.dateTo
      }

      if (this.$route.query.agencyId) {
        this.selectedAgencyId = this.$route.query.agencyId
      }
    },
    updateQueryParams (providedData) {
      const paramsObj = {
        searchKeywords: this.searchKeywords && this.searchKeywords.split(' ').map((o) => o.trim()),
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        agencyId: this.selectedAgencyId,
        page: this.pagination.page,
        rowsPerPage: this.pagination.rowsPerPage,
        descending: this.pagination.descending,
        sortBy: this.sortBy,
        ...providedData
      }

      this.lastParams = updateQueryParams({ paramsObj, route: this.$route, lastParams: this.lastParams })
    },
    hasUserType (role) {
      const { signedInUser } = this.$props
      return (signedInUser && isAuthorized(signedInUser, role))
    },
    onPagination () {
      if (this.componentMounted) {
        this.searchRoutes(this.selectedPassengerTab)
      }
    },
    onFilterByAgency (val) {
      this.selectedAgencyId = val ? this.agencies.find((o) => (o.value === val)).value : null
      this.updateQueryParams({ agencyId: val })
      this.searchRoutes(1)
    },
    /**
     * @param {number} setPage - force the page to be this over whatever pagination has
     * @returns {Promise<void>}
     */
    async searchRoutes (setPage) {
      try {
        let order
        let promises = []
        this.apiInProgress = true
        const { sortBy, descending, page, rowsPerPage } = this.pagination
        const offset = ((setPage || page) - 1) * (rowsPerPage || 0)
        const limit = rowsPerPage

        if (sortBy) {
          order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
        }

        promises.push(findStRoutes({
          keywords: String(this.searchKeywords).trim(),
          agencyId: this.selectedAgencyId,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          limit,
          offset,
          order
        }))

        promises.push(findTotalStRoutes({
          keywords: String(this.searchKeywords).trim(),
          agencyId: this.selectedAgencyId,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo
        }))

        const [results, totalPendingCountResult] = await Promise.all(promises)

        this.totalPendingPassengers = totalPendingCountResult

        this.routes = results.map((o) => {
          return this.setRoute(o)
        })
      } finally {
        this.apiInProgress = false
      }
    },
    setRoute ({ id, label, routeIdList, agencyId, dateCreated, dateUpdated, routeLineData }, agencies) {
      const newObj = Object.assign({}, { id, label, routeIdList, agencyId, dateCreated, dateUpdated, routeLineData })

      // console.warn('agencies=>', agencies)
      newObj.agencyLabel = this.agencies.find((o) => (o.value === agencyId))?.text || ''
      newObj.dateCreatedLabel = newObj.dateCreated ? `${formatTime(newObj.dateCreated)}, ${formatDate(newObj.dateCreated)}` : ''
      newObj.dateUpdatedLabel = newObj.dateUpdated ? `${formatTime(newObj.dateUpdated)}, ${formatDate(newObj.dateUpdated)}` : ''

      return newObj
    },
    loadRoute (routeId) {
      this.$router.push({ name: 'routes-route', params: { routeId } })
    }
  }
}

function initTableHeaders () {
  this.headers = [
    {
      text: 'Label',
      align: 'left',
      sortable: true,
      value: 'label'
    },
    {
      text: 'Route ID List',
      align: 'left',
      sortable: false
    },
    {
      text: 'Agency ID',
      align: 'left',
      sortable: true,
      value: 'agencyId'
    },
    {
      text: 'Date Created',
      align: 'left',
      sortable: true,
      value: 'dateCreated'
    },
    {
      text: 'Date Updated',
      align: 'left',
      sortable: true,
      value: 'dateUpdated'
    },
    { text: 'Actions', sortable: false }
  ]
}
